<template>
  <div class="editable-cell">
    <div v-if="editable" class="editable-cell-input-wrapper">
      <a-select
        :value="value"
        style="width: 100%"
        show-search
        :default-active-first-option="false"
        :filter-option="false"
        :not-found-content="null"
        @search="handleSearch"
        @change="handleChange"
        placeholder="请输入供应商"
      >
        <a-select-option
          v-for="item in dataSel"
          :value="item.id"
          :key="item.id"
          >{{ item.name }}
        </a-select-option>
      </a-select>
      <a-icon type="check" class="editable-cell-icon-check" @click="check" />
    </div>
    <div v-else class="editable-cell-text-wrapper">
      {{supplierText}}
      <a-icon
        type="edit"
        class="editable-cell-icon"
        :class="value ? '': 'editable-cell-empty'"
        @click="edit"
      />
    </div>
  </div>
</template>

<script>
import { getSupplierList } from "@/api/purchase.js";
import debounce from 'lodash/debounce';

export default {
  name: "EditableselectCell",
  props: {
    text: {
      type: String,
      default: () => undefined,
    },
    supplierText: {
      type: String,
      default: () => '',
    }
  },
  data() {
    return {
      value: this.text || undefined,
      editable: false,
      dataSel: [],
    };
  },
  created() {
    this.dataSel = [{
      id: this.value,
      name: this.supplierText
    }]
    this.handleSearch = debounce(this.handleSearch, 600);   
  },
  methods: {
    handleSearch(value) {
      console.log("handleSearch-sel", value);

      let params = {
        supplierName: value,
      }
      getSupplierList(params).then(res => {
        console.log('supplierName', res)
        this.dataSel = res;
      });
    },
    handleChange(e) {
      console.log("handleChange-sel", e);
      const value = e;
      this.value = value;
    },
    check() {
      console.log('check')
      this.editable = false;
      this.$emit("changeCell", this.value);
    },
    edit() {
      console.log('edit')
      this.editable = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.editable-cell {
  position: relative;
  line-height: 26px;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
  color: #1890ff;
}

.editable-cell-icon {
  line-height: 32px;
  // display: none;
}

.editable-cell-empty {
  top: -6px;
}

.editable-cell-icon-check {
  line-height: 32px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
</style>
