import {
  postRequest,
  // deleteRequest,
  getRequest,
  updateRequest,
} from "@/utils/http";

/** 创建商品 */
export const create = (params) =>
  postRequest(`/production/goods/create`, params);

/** 关联产品接口 */
export const getBoms = (params) =>
  getRequest(`/production/goods/getBoms`, params);

/** 商品列表 */
export const getList = (params) =>
  getRequest(`/production/goods/getList`, params);

/** 商品详情物料列表 */
export const getGoodsItem = (params) =>
  getRequest(`/production/goods/getGoodsItem`, params);

/** 商品更新 */
export const update = (params) =>
  updateRequest(`/production/goods/update`, params);

/** 商品下架 */
export const downShelf = (params) =>
  getRequest(`/production/goods/downShelf`, params);

/** 商品上架 */
export const upShelf = (params) =>
  getRequest(`/production/goods/upShelf`, params);

/** 查询商品信息 */
export const getGoodsInfo = (id) =>
  getRequest(`/production/goods/${id}`);

/** 快速编辑-供应商 */
export const updateSupplierAsync = (params) =>
  postRequest(`/production/goods/updateSupplierAsync`, params);

/** 快速编辑-单价 */
export const updatepriceAsync = (params) =>
  postRequest(`/production/goods/updatepriceAsync`, params);

/** 展示物料配置信息 */
export const getMaterialSelection = (params) =>
  postRequest(`/production/goods/getMaterialSelection`, params);

/** 添加物料配置信息 */
export const materialSupplierSave = (params) =>
  postRequest(`/production/goods/materialSupplierSave`, params);

/** 获取供应商下拉框数据 */
export const getSupplierList = (params) =>
  getRequest(`/common/supplier/getlist`, params);
