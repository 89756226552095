<template>
  <div class="bgStyle">
    <div class="head">
      {{ goodsInfo.goodsName }}
    </div>
    <div class="desc">
      <a-row :gutter="16">
        <a-col :span="8" class="cond">
          <label style="width: 90px;">商品编码：</label>
          <span>{{ goodsInfo.goodsCode }}</span>
        </a-col>
        <a-col :span="8" class="cond">
          <label style="width: 90px;">商品分类：</label>
          <span>{{ goodsInfo.goodsClassificationName }}</span>
        </a-col>
        <a-col :span="8" class="cond">
          <label style="width: 90px;">BOM版本：</label>
          <span>{{ goodsInfo.versionText }}</span>
        </a-col>
      </a-row>
    </div>

    <div class="goods">
      <div class="tit">商品BOM</div>
      <div>
        <div class="table-btn">
          <a-space>
            <a-button type="primary" @click="handleExpand">
              全部展开
            </a-button>
            <a-button type="primary" @click="handleFold">
              全部收起
            </a-button>
            <a-button @click="back">返回</a-button>
          </a-space>          
        </div>
        <a-table 
          :data-source="dataSource" 
          :columns="columns"
          :rowKey="(record) => record.materialItemId"
          childrenColumnName="childBom"
          :pagination="pagination"
          @change="handleTableChange"
          :expanded-row-keys.sync="expandedRowKeys"
        >
          <template slot="supplierId" slot-scope="text, record">
            <template v-if="record.sourceText == '外购'">
              <editableselect-cell
                :text="text"
                :supplierText="record.supplierText"
                @changeCell="onCellChange(record, 'supplierId', $event)"
              />     
            </template>
            <template v-else>
              {{ record.supplierText }}
            </template>                   
          </template>
          <template slot="price" slot-scope="text, record">
            <template v-if="record.sourceText == '外购'">
              <editable-cell
                :text="text"
                @changeCell="onCellChange(record, 'price', $event)"
              />
            </template>
            <template v-else>
              {{ text }}
            </template>            
          </template>
          <template slot="oper" slot-scope="text, record">
            <a @click="onEdit(record)">编辑</a>
          </template>
        </a-table>
      </div>
    </div>

    <a-modal
      v-model="visible"
      title="物料采购配置"
      :maskClosable="false"
      :width="1000"
      :destroyOnClose="true"
      :footer="null"      
    >
      <material-modal
        @cancel="onCancel"
        @save="saveConfig"
        :materialItemIdOne="materialItemIdOne"
        :bomId="bomId"
        :parentMaterialItemId="parentMaterialItemId"
      />
    </a-modal>
  </div>
</template>

<script> 
import EditableselectCell from "./components/EditableselectCell.vue";
import EditableCell from "./components/EditableCell.vue";
import MaterialModal from "./components/MaterialModal.vue";
import { getGoodsItem, getGoodsInfo, updatepriceAsync, updateSupplierAsync } from "@/api/purchase.js";

const columns = [
  {
    title: '物料名称',
    dataIndex: 'materialName',
  },
  {
    title: '物料编码',
    dataIndex: 'fullCode',
  },  
  {
    title: '版本',
    dataIndex: 'versionText',
  }, 
  {
    title: '来源',
    dataIndex: 'sourceText',
  }, 
  {
    title: '供应商',
    dataIndex: 'supplierId',
    scopedSlots: { customRender: 'supplierId' },
    width: '300px',
  },
  {
    title: '用量',
    dataIndex: 'quantity',
  },
  {
    title: '计价单位',
    dataIndex: 'unitName',
  },
  {
    title: '单价',
    dataIndex: 'price',
    scopedSlots: { customRender: 'price' },
    width: '120px',
  },
  {
    title: '操作',
    dataIndex: 'oper',
    scopedSlots: { customRender: 'oper' },
  }, 
];

export default {
  name: "SalesManageDetail",
  components: {
    EditableselectCell,
    EditableCell,
    MaterialModal,
  },
  props: {
    goodsId: { type: String, default: () => "" },
    materialItemId: { type: String, default: () => "" },
  },
  data() {
    return {
      goodsInfo: {},

      dataSource: [],
      columns,
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
        skipCount: 0,
      },
      expandedRowKeys: [],

      visible: false,
      materialItemIdOne: '',
      bomId: '',
      parentMaterialItemId: '',      
    }
  },
  created() {
    this.getInfoData();
    this.getData();
  },
  methods: {
    getInfoData() {
      getGoodsInfo(this.goodsId).then((res) => {
        this.goodsInfo = res;
      })
    },
    getData() {
      let pageSize = this.pagination.pageSize;
      let skipCount = (this.pagination.current - 1) * pageSize;

      let params = {
        goodsId: this.goodsId,
        materialItemId: this.materialItemId,
        skipCount,
        maxResultCount: pageSize,
      };

      getGoodsItem(params).then((res) => {
        console.log("getGoodsItem", res);
        this.dataSource = res.items;
        this.pagination.total = res.totalCount;
        this.skipCount = params.skipCount;
      });
    },
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.getData();
    },
    back() {
      this.$router.go(-1);
    },
    onCellChange(record, dataIndex, value) {
      console.log('onCellChange', record, dataIndex, value)
      if(dataIndex == "price") {
        let params = {
          materialItemId: record.materialItemId,
          price: value,
        };

        updatepriceAsync(params).then((res) => {
          console.log("updatepriceAsync", res);
          if(res) {
            this.refreshList();
          }
        });
      } else if(dataIndex == "supplierId") {
        if(value) {
          let params = {
            materialItemId: record.materialItemId,
            supplierId: value,
          };

          updateSupplierAsync(params).then((res) => {
            console.log("updateSupplierAsync", res);
            if(res) {
              this.refreshList();
            }
          });
        }        
      }
    },
    refreshList() {
      this.getData();
    },
    handleExpand() {
      this.recursionData(this.dataSource);
    },
    recursionData(data) {
      data.forEach(item => {
        if(item.hasChild) {
          if(!this.expandedRowKeys.includes(item.materialItemId)) {
            this.expandedRowKeys.push(item.materialItemId);
            this.recursionData(item.childBom);
          }
        }
      });
    },
    handleFold() {
      this.expandedRowKeys = [];
    },
    onEdit(record) {
      this.visible = true;

      this.materialItemIdOne = record.materialItemId;
      this.bomId = record.bomId;
      this.parentMaterialItemId = record.parentMaterialItemId;
    },
    saveConfig() {
      console.log('saveConfig')
    },
    onCancel() {
      this.visible = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.oper {
  text-align: right;
}

.head {
  font-size: 18px;
  font-weight: bold;  
  padding-bottom: 6px;
  margin-bottom: 16px;
}

.desc {
  margin-bottom: 16px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 16px;
  font-size: 14px;
  label {
    font-weight: bold;
  }
}

.tit {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}

.table-btn {
  margin-bottom: 12px;
  text-align: right;
}
</style>
