<template>
  <div class="wrap">
    <a-form-model
      ref="configForm"
      :model="dynamicForm"
      :rules="rules"
      v-bind="formItemLayoutWithOutLabel"
    >
      <a-form-model-item v-bind="formItemLayout" label="物料选择">
        <a-select 
          v-model="dynamicForm.material" 
          style="width: 40.5%; margin-right: 8px"
        >
          <a-select-option value="shanghai">
            Zone one
          </a-select-option>
          <a-select-option value="beijing">
            Zone two
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <a-row style="width: 115%">
          <a-col :span="12">
            <a-form-model-item 
              v-bind="formItemLayout" 
              label="默认供应商"
              prop="value"
            >
              <a-select
                :value="dynamicForm.value"
                show-search
                :default-active-first-option="false"
                :filter-option="false"
                :not-found-content="null"
                @search="handleSearch"
                @change="handleChange"
                placeholder="请输入供应商"
                style="width: 80%"
              >
                <a-select-option
                  v-for="item in dataSel"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="5">
            <a-form-model-item 
              v-bind="formItemLayout" 
              label="单价"
              prop="price"
            >
              <a-input-number
                :value="dynamicForm.price"
                @change="handleChangeInput"
                :min="0"
                style="width: 80%"
              />
            </a-form-model-item>            
          </a-col>
        </a-row>
      </a-form-model-item>

      <a-form-model-item v-bind="formItemLayoutWithOutLabel">
        <a-button type="dashed" style="width: 20%" @click="addDomain">
          <a-icon type="plus" /> 添加备选供应商
        </a-button>
      </a-form-model-item>

      <a-form-model-item
        v-for="item in dynamicForm.supplierArr"
        :key="item.key"
      >
        <a-row style="width: 115%">
          <a-col :span="12">
            <a-form-model-item 
              v-bind="formItemLayout" 
              label="备选供应商"
              prop="value"
            >
              <a-select
                :value="item.value"
                show-search
                :default-active-first-option="false"
                :filter-option="false"
                :not-found-content="null"
                @search="handleSearch"
                @change="handleChangeDynamic($event, item)"
                placeholder="请输入供应商"
                style="width: 80%"
              >
                <a-select-option
                  v-for="obj in dataSel"
                  :value="obj.value"
                  :key="obj.value"
                  >{{ obj.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="5">
            <a-form-model-item 
              v-bind="formItemLayout" 
              label="单价"
              prop="price"
            >
              <a-input-number
                :value="item.price"
                @change="handleChangeInputDynamic($event, item)"
                :min="0"
                style="width: 80%"
              />
            </a-form-model-item>            
          </a-col>
          <a-col :span="1">
            <a-icon
            v-if="dynamicForm.supplierArr.length > 1"
              class="dynamic-delete-button"
              type="minus-circle-o"
              @click="removeDomain(item)"
            />
          </a-col>          
        </a-row>        
      </a-form-model-item>

      <a-form-model-item v-bind="formItemLayout" label="备注">
        <a-textarea
          v-model="dynamicForm.remark" 
          style="width: 75%;"
        >
        </a-textarea>
      </a-form-model-item>
      <a-form-model-item 
        :offset="10"
        v-bind="{wrapperCol: {
          span: 14,
          offset: 10
        }}"
      >
        <a-button type="primary" html-type="submit" @click="submitForm('configForm')">
          保存配置
        </a-button>
        <a-button style="margin-left: 10px" @click="cancel">
          取消
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import { getMaterialSelection } from "@/api/purchase.js";

export default {
  props: {
    materialItemIdOne: { type: String, default: () => "" },
    bomId: { type: String, default: () => "" },
    parentMaterialItemId: { type: String, default: () => "" },
  },
  data() {
    return {
      formItemLayout: {
        labelCol: {
          span: 6 
        },
        wrapperCol: {
          span: 18
        },
      },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          span: 21,
          offset: 3
        },
      },
      dynamicForm: {
        material: '',
        supplierArr: [],
        remark: '',
        value: '',
        price: '',
      },
      rules: {
        value: [
          { required: true, message: '必填项', trigger: 'blur' },
        ],
        price: [
          { required: true, message: '必填项', trigger: 'blur' },
        ],
      },
      dataSel: [],
    };
  },
  created() {
    this.dataSel.push({
      "value": '123',
      "name": "qww",
    });

    this.getData();
  },
  methods: {
    getData() {
      let params = {
        materialItemId: this.materialItemIdOne,
        bomId: this.bomId,
        parentMaterialItemId: this.parentMaterialItemId,
      };

      getMaterialSelection(params).then((res) => {
        console.log("getMaterialSelection", res);

      });
    },
    submitForm(formName) {
      console.log('this.dynamicForm', this.dynamicForm)
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert('submit!');

          this.$emit('save');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    cancel() {
      this.$emit('cancel');
    },
    removeDomain(item) {
      let index = this.dynamicForm.supplierArr.indexOf(item);
      if (index !== -1) {
        this.dynamicForm.supplierArr.splice(index, 1);
      }
    },
    addDomain() {
      this.dynamicForm.supplierArr.push({
        key: Date.now(),
        value: '',
        price: '',
      });
    },
    handleSearch(value) {
      console.log("handleSearchSel", value);

      // fetch(value, data => (this.dataSel = data));
    },
    handleChange(e) {
      console.log("handleChangeSel", e);
      const value = e;
      this.dynamicForm.value = value;

      // fetch(value, data => (this.dataSel = data));
    },
    handleChangeInput(e) {
      console.log("handleChangeInput", e);
      this.dynamicForm.price = e || 0;

    },
    handleChangeDynamic(e, record) {
      console.log(e, record)
      this.dynamicForm.supplierArr.forEach(item => {
        if(item.key == record.key) {
          item.value = e;
        }
      });
    },
    handleChangeInputDynamic(e, record) {
      console.log(e, record)
      const price = e || 0;

      this.dynamicForm.supplierArr.forEach(item => {
        if(item.key == record.key) {
          item.price = price;
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.wrap {
  .ant-form-item {
    margin-bottom: 12px;
  }
}
</style>
